<template>
  <div class="contact">
    <div class="title">联系我们</div>
    <div class="divider"></div>
    <div class="wrapper">
      <div class="inner">
        <div class="item" v-for="(item, index) in contactItems" :key="index">
          <div class="icon-wrapper">
            <img :src="item.img" alt="" />
          </div>
          <div class="contact-title">{{ item.title }}</div>
          <div class="contact-divider"></div>
          <div class="contact-infos">
            <div
              class="contact-info"
              v-for="(info, infoIndex) in item.infos"
              :key="infoIndex"
            >
              {{ info }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon1 from '../assets/images/about/icon-1.png';
import icon2 from '../assets/images/about/icon-2.png';
import icon3 from '../assets/images/about/icon-3.png';

export default {
  name: 'AboutContact',
  props: {},
  data() {
    return {
      contactItems: [
        {
          title: '联系电话',
          infos: ['400-858-6686'],
          img: icon1,
        },
        {
          title: '商务合作',
          infos: ['business@coalchinajy.com'],
          img: icon2,
        },
        {
          title: '联系地址',
          infos: ['上海市青浦区诸光路1588弄', '虹桥世界中心B座6楼'],
          img: icon3,
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.contact {
  width: 100%;
  overflow: hidden;
  text-align: center;
  .title {
    margin-top: @size100;
    font-size: @size40;
    font-weight: 700;
    text-align: center;
    color: #666;
  }
  .divider {
    display: inline-block;
    text-align: center;
    width: @size60;
    min-width: 30px;
    height: @size4;
    min-height: 2px;
    background: #0066dd;
    margin-top: @size30;
  }
  .wrapper {
    width: 100%;
    margin-top: @size100;
    margin-bottom: @size180;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    .inner {
      width: @size1200;
      min-width: 600px;
      display: flex;
      justify-content: space-between; /* 水平居中 */
      align-items: center; /* 垂直居中 */
      .item {
        border: 1px solid #999999;
        border-radius: 10px;
        padding: @size24;
        height: @size450;
        min-height: 225px;
        .icon-wrapper {
          width: @size330;
          min-width: 165px;
          height: @size100;
          min-height: 50px;
          background: #0066dd;
          text-align: center;
          vertical-align: middle;
          margin-bottom: @size60;
          padding-top: @size20;
          border-radius: 5px;
          img {
            width: @size60;
            height: @size60;
            min-width: 30px;
            min-height: 30px;
          }
        }
        .contact-title {
          font-size: 26px;
          color: #333;
        }
        .contact-divider {
          display: inline-block;
          text-align: center;
          width: @size60;
          min-width: 30px;
          height: @size4;
          min-height: 2px;
          background: #0066dd;
          margin-top: @size50;
          margin-bottom: @size50;
        }
        .contact-info {
          font-size: @size20;
          color: #2f2725;
        }
      }
    }
  }
}
</style>
