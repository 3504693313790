<template>
  <div class="about-certs" id="about-certs">
    <div class="title">荣誉资质</div>
    <div class="divider"></div>
    <div class="wrapper">
      <div class="inner">
        <el-carousel height="7.8rem" indicator-position="outside" :autoplay="false">
          <el-carousel-item v-for="(item, index) in items" :key="index">
            <div class="item">
              <div
                class="child"
                v-for="(child, childIndex) in item"
                :key="childIndex"
                @click="onClick"
              >
                <div class="child-top">
                  <el-image :src="child.img" :fit="contain" :preview-src-list="images"> </el-image>
                </div>

                <div class="cert-name">
                  {{ child.title }}
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import image1 from '../assets/images/about/cert/1.jpg';
import image2 from '../assets/images/about/cert/2.jpg';
import image3 from '../assets/images/about/cert/3.jpg';
import image4 from '../assets/images/about/cert/4.jpg';
import image5 from '../assets/images/about/cert/5.jpg';
import image6 from '../assets/images/about/cert/6.jpg';
import image7 from '../assets/images/about/cert/7.jpg';
import image8 from '../assets/images/about/cert/8.jpg';
import image9 from '../assets/images/about/cert/9.jpg';
import image10 from '../assets/images/about/cert/10.jpg';
import image11 from '../assets/images/about/cert/11.jpg';
import image12 from '../assets/images/about/cert/12.jpg';
import image13 from '../assets/images/about/cert/13.jpg';
import image14 from '../assets/images/about/cert/14.jpg';
import image15 from '../assets/images/about/cert/15.png';
import image16 from '../assets/images/about/cert/16.png';
import image17 from '../assets/images/about/cert/17.png';
import image18 from '../assets/images/about/cert/18.png';
import image19 from '../assets/images/about/cert/19.png';
import image20 from '../assets/images/about/cert/20.png';
import image21 from '../assets/images/about/cert/21.png';
import image22 from '../assets/images/about/cert/22.png';
import image23 from '../assets/images/about/cert/23.png';
import image24 from '../assets/images/about/cert/24.png';
import image25 from '../assets/images/about/cert/25.png';
import image26 from '../assets/images/about/cert/26.png';

export default {
  name: 'AboutCerts',
  props: {},
  computed: {
    items() {
      return this.sliceArray(this.certs, this.size);
    },
    height() {
      const width = Math.max(window.innerWidth, 960);
      return `${(220 / 1920) * width}px`;
    },
  },
  data() {
    const certs = [
      {
        title: '一种智慧校园用通行识别装置',
        img: image15,
      },
      {
        title: '一种5G通信全方位测试设备',
        img: image16,
      },
      {
        title: '一种物联网设备报警装备',
        img: image17,
      },
      {
        title: '煤矿只能远程安全监控及报警控制系统V1.0',
        img: image1,
      },
      {
        title: '智慧校园信息管理平台V1.0',
        img: image2,
      },
      {
        title: '智慧矿山三维GIS综合信息管理系统V1.0',
        img: image3,
      },
      {
        title: '新一代智慧数据交换平台系统V1.0',
        img: image4,
      },
      {
        title: '智慧矿山模拟救援系统V1.0',
        img: image5,
      },
      {
        title: '基于SOA的行业大数据软件分析平台V1.0',
        img: image6,
      },
      {
        title: '数据信息采集可视化系统V1.0',
        img: image7,
      },
      {
        title: 'AI数据在线分析统计软件V1.0',
        img: image8,
      },
      {
        title: 'MR混合现实互动展示云平台',
        img: image9,
      },
      {
        title: '互联网智慧校园服务终端管理系统V1.0',
        img: image10,
      },
      {
        title: '基于物联技术的智慧矿山云服务平台V1.0',
        img: image11,
      },
      {
        title: '智能煤矿机电设备可视化综合管理平台V1.0',
        img: image12,
      },
      {
        title: '求职赋能云职道软件系统V1.0',
        img: image13,
      },
      {
        title: '煤炭教育协会综合服务平台V1.0',
        img: image14,
      },
      {
        title: 'ISO9001',
        img: image25,
      },
      {
        title: 'ISO9001',
        img: image26,
      },
      {
        title: 'AAA诚信经营示范单位',
        img: image18,
      },
      {
        title: 'AAA企业信用等级证书双网',
        img: image19,
      },
      {
        title: 'AAA企业资信等级证书单网',
        img: image20,
      },
      {
        title: 'AAA质量服务诚信单位',
        img: image21,
      },
      {
        title: 'AAA重服务守信用企业',
        img: image22,
      },
      {
        title: 'AAA重合同守信用企业',
        img: image23,
      },
      {
        title: 'AAA重质量守信用企业',
        img: image24,
      },
    ];
    const images = certs.map((it) => it.img);
    return {
      size: 6,
      images,
      certs,
    };
  },
  methods: {
    sliceArray(arr, size) {
      const arr2 = [];
      let i = 0;
      while (i < arr.length) {
        arr2.push(arr.slice(i, i + size));
        i += size;
      }
      return arr2;
    },
    onClick() {
      this.changeSize();
      setTimeout(() => {
        this.bindEvent('el-image-viewer__next');
        this.bindEvent('el-image-viewer__prev');
        this.bindCloseEvent();
      }, 1000);
    },
    changeSize(timeout = 0) {
      setTimeout(() => {
        const eles = document.getElementsByClassName('el-icon-zoom-out');
        if (eles[0]) {
          eles[0].click();
        }
      }, timeout);
    },
    changeSizeByNext() {
      this.changeSize(500);
    },
    bindEvent(name) {
      const eles = document.getElementsByClassName(name);
      eles.forEach((it) => {
        it.addEventListener('click', this.changeSizeByNext);
      });
    },
    bindCloseEvent() {
      const eles = document.getElementsByClassName('el-image-viewer__close');
      eles.forEach((it) => {
        it.addEventListener('click', () => {
          this.removeEvent('el-image-viewer__next');
          this.removeEvent('el-image-viewer__prev');
        });
      });
    },
    removeEvent(name) {
      const eles = document.getElementsByClassName(name);
      eles.forEach((it) => {
        it.removeEventListener('click', this.changeSizeByNext);
      });
    },
  },
};
</script>
<style scoped lang="less">
.about-certs {
  background-image: url(../assets/images/about/cert/bg.jpg);
  background-size: cover;
  height: 1200px;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  .title {
    margin-top: 102px;
    font-size: @size40;
    font-weight: 700;
    text-align: center;
    color: #666;
    margin-bottom: 30px;
  }
  .divider {
    display: inline-block;
    text-align: center;
    width: @size60;
    min-width: 30px;
    height: @size4;
    min-height: 2px;
    background: #0066dd;
  }
  .wrapper {
    width: 100%;
    margin-top: 110px;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    .inner {
      width: @size1200;
      min-width: 600px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .item {
      width: @size1200;
      min-width: 600px;
      display: flex;
      justify-content: space-around;
      align-items: baseline;
      text-align: center;
      color: #666;
      flex-wrap: wrap;
      .child {
        flex: 0 0 30%;
        margin-bottom: 30px;
        cursor: pointer;
        .child-top {
          display: flex;
          align-items: center; /* 垂直居中 */
          justify-content: center;
          height: 300px;
        }
        img {
          width: 200px;
          border: solid 10px #55311b;
        }
        .cert-name {
          font-size: @size20;
          color: #3e3a39;
          margin-top: @size20;
        }
      }
    }
  }
  .modal {
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    display: none;
    position: absolute;
    z-index: 100;
    // display: flex;
    justify-content: center;
    align-items: center;
    .modal-inner {
      width: 600px;
      position: relative;
      padding-top: @size100;
      color: #fff;
      text-align: center;
      .modal-close {
        width: @size48;
        min-width: 24px;
        height: @size48;
        min-height: 24px;
        background-image: url(../assets/images/business/modal-close.png);
        background-size: cover;
        cursor: pointer;
        position: absolute;
        right: @size20;
        top: @size20;
      }
      .modal-title {
        font-size: 32px;
        font-weight: 700;
      }
      .modal-content {
        margin-top: 20px;
        img {
          width: 500px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.about-certs {
  .el-carousel--horizontal {
    width: 100%;
  }
  .el-carousel__indicator--horizontal {
    padding-top: @size100;
  }
  .el-carousel__indicators--outside button {
    width: @size120;
    min-width: 60px;
    height: @size10;
    min-height: 5px;
    border-radius: 5px;
  }
  .el-carousel__arrow {
    background: none;
    i {
      font-size: @size80;
      color: #939497;
      font-weight: 700;
    }
  }
  .is-active {
    .el-carousel__button {
      background: #fff;
    }
  }
  .el-image {
    img {
      border: solid 10px #55311b;
      width: 200px;
    }
  }
}
</style>
