<template>
  <div class="about">
    <Header theme="black" :currentIndex="headerIndex" />
    <AboutPics />
    <AboutIntro />
    <AboutPosition />
    <AboutCerts />
    <AboutContact />
    <Footer />
    <div class="footer"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import AboutPics from '@/components/AboutPics.vue';
import AboutIntro from '@/components/AboutIntro.vue';
import AboutPosition from '@/components/AboutPosition.vue';
import AboutContact from '@/components/AboutContact.vue';
import AboutCerts from '@/components/AboutCerts.vue';

export default {
  name: 'About',
  components: {
    Header,
    Footer,
    AboutPics,
    AboutIntro,
    AboutPosition,
    AboutContact,
    AboutCerts,
  },
  data() {
    return {
      headerIndex: 4,
    };
  },
};
</script>
<style lang="less" scoped>
</style>
