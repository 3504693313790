<template>
  <div class="intro">
    <img src="../assets/images/about/intro.jpg" alt="" />
    <div class="wrapper">
      <div class="title">公司介绍</div>
      <div class="inner">
        <div v-for="(item, index) in pItems" :key="index" class="item">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutIntro',
  props: {},
  data() {
    return {
      pItems: [
        '上海千沐智能科技有限公司（下称 千沐科技）以人工智能技术为基石，为矿山智能化及AI教育持续赋能，公司建设和运营大数据产教融合平台中国煤炭教育协会赋能云平台，覆盖原煤炭部下属近百所行业高职和高校，包括太原理工大学、河南理工大学、中国矿业大学、河北工程大学、安徽理工大学、湖南科技大学、山东科技大学、华北理工大学、华北科技学院等知名高校，行业千余家企业，包括百余家500强集团，如神华、中煤科工、中煤集团、山东能源、晋煤集团、冀中能源、开滦集团、淮矿集团、平煤神马、徐矿集团等超大型企业集团。',
        '立足深耕智慧矿山领域，以实现矿山开采安全、无人、高效、清洁为使命，以成为智慧化矿山系统解决方案的领导者为愿景。公司下设千沐智能矿山研究中心、解决方案中心、技术咨询中心、教育培训中心等板块，以成为智慧矿山系统解决方案的核心供应商为目标。',
      ],
    };
  },
};
</script>
<style scoped lang="less">
.intro {
  width: 100%;
  height: @size910;
  min-height: 455px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  .wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    .title {
      margin-top: @size90;
      font-size: @size40;
      font-weight: 700;
      color: #0066dd;
    }
    .inner {
      width: @size1410;
      height: 620px;
      margin-left: @size254;
      margin-top: @size28;
      border: 2px solid rgba(0, 102, 221, 0.8);
      background: rgba(255, 255, 255, 0.85);
      padding: 120px @size130;
      color: #666666;
      font-size: @size22;
      line-height: 2;
      text-align: left;
      display: flex;
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中 */
      flex-wrap: wrap;
      .item {
        text-align: left;
        flex: 100%;
      }
    }
  }
}
</style>
