<template>
  <div class="position">
    <div class="title">公司定位</div>
    <div class="divider"></div>
    <div class="wrapper">
      <div class="inner">
        <div class="item" v-for="(item, index) in positionItems" :key="index">
          <img :src="item.img" alt="" />
          <div class="position-title">{{ item.title }}</div>
          <div class="position-infos">
            <div
              class="position-info"
              v-for="(info, infoIndex) in item.infos"
              :key="infoIndex"
            >
              {{ info }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pic1 from '../assets/images/about/yuanjing.png';
import pic2 from '../assets/images/about/shiming.png';
import pic3 from '../assets/images/about/mubiao.png';

export default {
  name: 'AboutPosition',
  props: {},
  data() {
    return {
      positionItems: [
        {
          title: '成为',
          infos: ['智慧化矿山系统', '解决方案的领导者'],
          img: pic1,
        },
        {
          title: '实现',
          infos: ['矿山开采安全、', '无人、高效、清洁'],
          img: pic2,
        },
        {
          title: '成为',
          infos: ['智慧矿山系统解决', '方案的核心供应商'],
          img: pic3,
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.position {
  background-image: url(../assets/images/about/position.jpg);
  background-size: cover;
  height: @size910;
  min-height: 455px;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  .title {
    margin-top: @size90;
    font-size: @size40;
    font-weight: 700;
    text-align: center;
    color: #666;
  }
  .divider {
    display: inline-block;
    text-align: center;
    width: @size60;
    min-width: 30px;
    height: @size4;
    min-height: 2px;
    background: #0066dd;
    margin-top: @size30;
  }
  .wrapper {
    width: 100%;
    margin-top: @size114;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    .inner {
      width: @size1200;
      min-width: 600px;
      display: flex;
      justify-content: space-between; /* 水平居中 */
      align-items: center; /* 垂直居中 */
      .item {
        width: @size270;
        min-width: 135px;
        text-align: center;
        color: #666;
        img {
          width: @size270;
          min-width: 135px;
          height: @size270;
          min-height: 135px;
          margin-bottom: @size30;
        }
        .position-title {
          font-size: @size40;
          font-weight: 700;
          margin-bottom: @size20;
        }
        .position-info {
          font-size: @size20;
        }
      }
    }
  }
}
</style>
